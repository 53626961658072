<template>
  <div>success</div>
</template>

<script>
export default {
  name: 'NiceSuccess',
  mounted() {
    if (window.opener && window.opener.vm) {
      window.opener.vm.makeOwnerPhoneAuth(this.$route.query.authsess);
      window.close();
    } else {
      alert('잘못된 접근 입니다.');
      window.location.href = process.env.VUE_APP_GABIA_URL;
    }
  },
};
</script>

<style lang="scss" scoped></style>
